// this function create a link paths
export const generateSubNavigation = (router, subPath, id = 0, mainPath = 'admin') => {
  try {
    const subPathFind = router.config
      .find(route => route.path === mainPath)._loadedConfig.routes[0].children
      .find(route => route.path === subPath);
    const subPathFindNext = (subPathFind.children || subPathFind._loadedConfig.routes[0].children)
      .find(route => route.data && route.data.subnav);


    const result = `${subPathFind.path}/${id}/{children}`;


    const menu = (subPathFindNext.children || subPathFindNext._loadedConfig.routes[0].children)
      .filter(route => route.data && route.data.title) //only add a menu item for routes with a title set.
      .map(route => {

        const itemPath = route.path;

        const current = result.replace('{children}', itemPath);

        return {
          path: [`/${mainPath}/${current}`],
          title: route.data.title,
        };
      });

    const backLink = generateSubnavBackLink(router, subPath, mainPath);

    return {menu, backLink};
  } catch (error) {
    console.log(error);
    return {menu: {}, backLink: {}};
  }
};

export const generateSubnavBackLink = (router, subPath, mainPath = 'admin') => {
  try {
    const subPathFind = router.config
      .find(route => route.path === mainPath)._loadedConfig.routes[0].children
      .find(route => route.path === subPath);

    return {
      path: [`/${mainPath}/${subPathFind.path}`],
      title: subPathFind.data.title,
    };
  } catch (error) {
    console.log(error);
    return {
      path: [],
      title: '',
    };
  }
};
