import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '@app/../environments/environment';
import {Observable} from 'rxjs';
import {map, publishReplay, refCount} from 'rxjs/operators';
import {TeamManagementDto} from '@app/dto/TeamManagementDto';
import { ActiveDirectoryUser } from '@app/dto/ActiveDirectoryUser';
import { ResponsibilityDto } from '@app/components/assign-projects-to-team-members/ResponsibilityDto';

@Injectable({
  providedIn: 'root'
})
export class AdminTeamManagementService {
  private cache$: Observable<TeamManagementDto>[] = [];

  constructor(private http: HttpClient) {
  }

  public get(id: number, useCache: boolean = false): Observable<TeamManagementDto> {
    if (!this.cache$[id] || !useCache) {
      this.cache$[id] = this.http
        .get<TeamManagementDto>(`${environment.serverUrl}teammanagement/${id}`).pipe(
          map(data => data),
          publishReplay(1), // this tells Rx to cache the latest emitted
          refCount() // and this tells Rx to keep the Observable alive as long as there are any Subscribers
        );
    }

    return this.cache$[id];
  }

  public getTeamsList(): Observable<TeamManagementDto[]> {
    const url = `${environment.serverUrl}teammanagement/all`;
    return this.http.get<TeamManagementDto[]>(url);
  }

  public create(model: TeamManagementDto): Observable<TeamManagementDto> {
    return this.http.post<TeamManagementDto>(`${environment.serverUrl}teammanagement`, model);
  }

  public update(model: TeamManagementDto): Observable<TeamManagementDto> {
    return this.create(model);
  }

  public delete(id: number) {
    return this.http.delete(
      `${environment.serverUrl}teammanagement/${id}`);
  }

  public getTeamMembers(teamManager: ActiveDirectoryUser) : Observable<ResponsibilityDto[]>{
    return this.http.post<ResponsibilityDto[]>(`${environment.serverUrl}teammanagement/get-team-members`, teamManager);
  }

  clearCache(id: number | null = null) {
    if (this.cache$ && id) {
      this.cache$[id] = null;
    } else {
      this.cache$ = null;
    }
  }
}
